import * as React from 'react'
import { Link } from 'gatsby'

import './blocklink.css'

const BlockLinkFull = ({destination, title, children}) => {
    return(
        <Link class="block-link-half" to={destination}>
            <div class="block-link-content-wrapper">
                <h4 className="block-link-title">
                    {title}
                </h4>
                <p className="block-link-description">
                    {children}
                </p>
            </div>
        </Link>
    )
}

export default BlockLinkFull
