import * as React from 'react'

import './topimageheading.css'
import {GatsbyImage} from "gatsby-plugin-image";

const TopImageHeading = ({title, children}) => {
    return(
        <div className="top-image-section">
            <div className="top-image-section-wrapper">
                {children}
                <h2 className="top-image-section-text">{title}</h2>
            </div>
        </div>
    )
}

export default TopImageHeading
