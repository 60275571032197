import * as React from 'react'
import Layout from '../../components/Layout'
import LinkBar from '../../components/LinkBar'
import BlockLinkFull from "../../components/BlockLink/BlockLinkFull";
import TopImageHeading from "../../components/TopImageHeading/TopImageHeading";

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {
    middleCardImages,
    middleCardText,
    middleCardImage,
    middleCardWrapper
} from "../../components/styles/titlepage.module.css";
import {
    topImageSectionImage, blockLinkBox
} from "../../components/styles/mainpages.module.css"

//Global stylesheet
import "../../components/styles/global.css"
import BlockLinkPartial from "../../components/BlockLink/BlockLinkPartial";

const destinations = () => {
    return(
        <Layout pageTitle={"destinations"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Places to go in Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="A mountain of mystery. A shining metropolitan skyline. A complex underground cathedral. Discover limitless
                destinations at the official site of Tourism Bangbiangyang."/>

                <meta property="og:title" content="Places to go in Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="A mountain of mystery. A shining metropolitan skyline. A complex underground cathedral. Discover limitless
                destinations at the official site of Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/destinations"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/destinations">Places to go</Link>
            </LinkBar>

            <TopImageHeading title="Explore destinations">
                <StaticImage className={topImageSectionImage} src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg/640px-USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg" alt="."/>
            </TopImageHeading>

            <div className="midContentWrapper">
                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h5 data-sal="slide-up">
                            If you could live for a thousand years - there'd still be a thousand more places to go, and a thousand more things to see.
                        </h5>
                        <p data-sal="slide-up">
                            Just as you start to think your flight might never end, a paradise reveals itself beneath your feet. Dip below the clouds into
                            Bangbiangyang: an oasis of life, excitement, and adventure.
                            From a wintry mountain peak isolated in eternal fog to a golden coastline that's never blinded from the sun, the archipelago of Bangbiangyang
                            is home to an environment so diverse, you'd find nothing else like it.
                        </p>
                        <h3 className="genericHeading" data-sal="slide-up">
                            Cities of Bangbiangyang
                        </h3>
                        <div className={blockLinkBox}>
                            <BlockLinkFull title="Bangbiangyang" destination="./bangbiangyang">
                                An ancient capital steeped in millennia of culture and history. Between gleaming spires and gentle backstreets, traditional performances and
                                innovative displays, there's a place for anyone in the world's most prosperous urban metropolis.
                            </BlockLinkFull>
                            <BlockLinkFull title="Deprexxizawa" destination="./deprexxizawa">
                                In the depths of an expansive impact crater lies Deprexxizawa, a city encircled by towering mountainous terrain. Here, even the dimmest
                                of sunlight proves a rarity, but millions flock regardless to its mystical and esoteric allure.
                            </BlockLinkFull>
                            <BlockLinkFull title="Spankingrad" destination="./spankingrad">
                                Step into the neon glow of a city that never sleeps. Known for its unwavering nightlife, friendliness, and fun, Spankingrad
                                provides a sharp dose of energy into even the weariest of travelers.
                            </BlockLinkFull>
                            <BlockLinkFull title="Zifangsi" destination="./zifangsi">
                                Once a haven for stranded seafarers, the port city of Zifangsi is intertwined upon a hundred different cultures and traditions. In a thriving
                                testament to human cooperation, explore stunning historical artworks and immerse yourself in beliefs long since lost to time.
                            </BlockLinkFull>
                            <BlockLinkFull title="Litentia" destination="./litentia">
                                A city where the impossibilities of yesterday are the technologies of today. Ranked highly in global metrics for innovation, education, and
                                research, Litentia's 14 higher education institutions further the boundaries of humanity's knowledge - and even the shortest visit will further yours, too.
                            </BlockLinkFull>

                        </div>

                    </div>
                    <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                </div>


                <div className={middleCardWrapper}>
                    <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>

                    <div className={middleCardText}>
                        <h3 data-sal="slide-up">
                            Regions of Bangbiangyang
                        </h3>
                        <p data-sal="slide-up">
                            Across the Bangbiangyang archipelago are over a hundred islands, each with a unique story, history, and atmosphere. Relax on the calmest beaches or dive
                            headfirst into the action on an active and sacred volcano. Try a new local specialty dish every day for a hundred days. Or spend a weekend in a cottage,
                            overlooking a valley enshrouded in a permanent pink mist. The choices are yours.
                        </p>
                        <div className={blockLinkBox}>
                            <BlockLinkPartial title="Biang Island" destination="./yang">
                                The most populous island in Bangbiangyang, Biang is a valuable first stop for any visitor. While the glowing skyline of Bangbiangyang dominates the majority of
                                Biang, with all its adventures and opportunities within, there's still ample space for expansive fields and natural reserves.
                            </BlockLinkPartial>
                            <BlockLinkPartial title="Lanya Island" destination="./yang">
                                While relatively sparsely populated, Lanya's status as Bangbiangyang's largest island lends itself well to the confident adventure; one who seeks the
                                thrill of journeying through some of the planet's most treacherous mountain terrain.
                            </BlockLinkPartial>
                            <BlockLinkPartial title="Merinsul Bay" destination="./yang">
                                Many adventures in Merinsul start in Zifangsi's glimmering city streets, but a sea shaped by millions of years of volcanic activity has a sure
                                lot more to offer.
                            </BlockLinkPartial>
                            <BlockLinkPartial title="Sininjiki" destination="./yang">
                                Situated in Bangbiangyang's eastern fringes, Sininjiki is home to some of our most unique cultural and traditional experiences - from free-form
                                hallucinations to accelerated tree riding.
                            </BlockLinkPartial>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default destinations